// App.js
import React, { useState, useEffect, useRef } from 'react';
// import Quiz from './components/quizApp/Quiz';
import Quiz from '../components/quizApp/Quiz';
import Start from '../components/quizApp/Start';
import Result from '../components/quizApp/Result';
import { questions } from '../components/quizApp/questions';
import '../App.css';
import { refreshPage } from '../utils/utils';

// useRef - to handle scrollIntoView() when button is clicked. In this case I want the answer to scroll into view after the user clicks "afficher réponse".
// useRef() hook helps us to access DOM nodes directly.



export default function Home ()
{


  
  // SET STATES
  const [ questionIndex, setQuestionIndex ] = useState( 0 ); // index of questions array of objects
  const [ question, setQuestion ] = useState( {} ); // the question being shown now
  const [ questionNext, setQuestionNext ] = useState( {} ); // the question that follows
  const [ predictedAnswer, setPredictedAnswer ] = useState( 0 ); // the user's answer (the value of the slider)
  const [ predictedAnswerCheck, setPredictedAnswerCheck ] = useState( false ); // Has the user's answer been checked against the actual answer ?
  const [ showAnswer, setShowAnswer ] = useState( false ); // whether or not to reveal the answer
  const [ answerAssessment, setAnswerAssessment ] = useState( "" ); // the feedback the user receives depending on how close his/her answer is to the real answer
  const [ answerText, setAnswerText ] = useState( "" ); // the explanation the user receives depending on how close his/her answer is to the real answer
  const [ marks, setMarks ] = useState( 0 );
  const [ sliderMin, setSliderMin ] = useState( 1 ); // minimum value of slider scale
  const [ sliderMax, setSliderMax ] = useState( 100 ); // maximum value of slider scale
  const [ sliderDefault, setSliderDefault ] = useState( 1 ); // default Value of slider
  const [ valueSet, setValueSet ] = useState( false );

  // display controlling states
  const [ showStart, setShowStart ] = useState( true );
  const [ showQuiz, setShowQuiz ] = useState( false );
  const [ showResult, setShowResult ] = useState( false );

  const [ range, setRange ] = useState(100);
  const [ answerNb, setAnswerNb ] = useState(42);
  const [ percentage, setPercentage] = useState(42);
  // value of slider
  const [ value, setValue ] = useState( 1 );
  const [left, setLeft] = useState ([42]);
  const [ cssText, setCssText ] = useState("");
  const [sliderThumbCurrent, setSliderThumbCurrent] = useState("");
  // let sliderThumb = document.querySelector(".MuiSlider-thumb");
 //TODO Try putting useEffect stuff below in normal function
  let sliderThumb = document.querySelectorAll(".MuiSlider-thumb");


// console.log(sliderThumb, sliderThumb[1]);
useEffect( () => {
  setSliderThumbCurrent(sliderThumb[questionIndex]);
  console.log(sliderThumbCurrent);
  setValue( questions[ questionIndex ].answerNb);
  console.log(cssText);
  if (sliderThumbCurrent) 
  {
    console.log(sliderThumbCurrent.style)
    setCssText(`left: ${left.toFixed(0)};`)
    console.log(`${cssText}%`)
    sliderThumbCurrent.style.left =`${cssText}`
  }



  // if(sliderThumbCurrent.style);{
  //   console.log(sliderThumbCurrent.style);
  // }
  
  
  // let sliderThumbCurrent = sliderThumb[questionIndex];

  // console.log(sliderThumbCurrent.style.cssText);

// let sliderThumbLeft = sliderThumb[1].style.left;
// console.log(sliderThumbLeft);}

  // setThumbValues();
  //   if ((questions[ questionIndex + 1]) && showAnswer)
  //   {
  //   // let sliderThumb = document.getElementsByClassName("MuiSlider-thumb");
  //   let rangeValue=(questions[ questionIndex + 1].sliderMax) - (questions[ questionIndex + 1].sliderMin);
  //   let min = (questions[ questionIndex + 1].sliderMin);
  //   let max = (questions[ questionIndex + 1].sliderMax);
  //   let answerValue=( questions[ questionIndex + 1].answerNb)-(questions[ questionIndex + 1].sliderMin);
  //   let percentageValue=(((answerValue/rangeValue)*100))
  //   setRange(rangeValue);
  //     setAnswerNb(answerValue);
  //     setPercentage(percentageValue);
  //     setLeft(percentage);
  //     console.log(`range = ${range}, answerNb = ${answerNb}, percentage = ${percentage}, left = ${left} min=${sliderMin}, max=${sliderMax}`)

  //     // range = 100, answerNb = 42, percentage = 42, min=1, max=100
  //     // range = 180, answerNb = 80, percentage = 44.44444444444444, min=0, max=180
  //     // range = 30, answerNb = 5, percentage = 16.666666666666664, min=0, max=30
  //   } else if 
  //     ((questions[ questionIndex ]) && showAnswer)
  //     {
  //       let rangeValue=(questions[ questionIndex ].sliderMax) - (questions[ questionIndex ].sliderMin); // 60
  //       let answerValue=( questions[ questionIndex ].answerNb)-(questions[ questionIndex ].sliderMin); // 1948
  //       let min = (questions[ questionIndex ].sliderMin);
  //       let max = (questions[ questionIndex ].sliderMax);
  //       let percentageValue=(((answerValue/rangeValue)*100))
    
  //     setRange(rangeValue);
  //     setAnswerNb(answerValue);
  //     setPercentage(percentageValue);
  //     setLeft(percentage);
  //     console.log(`range = ${range}, answerNb = ${answerNb}, percentage = ${percentage}, min=${min}, max=${max}`) // range = 60, answerNb = 48, percentage = 80, min=1900, max=1960
  //     }
  //     // , percentage = ${percentage}, left = ${left}`);
  //     // console.log(`sliderThumb = ${sliderThumb} `);
  //     // let sliderThumbLeft = sliderThumb.style.left;
  //     // setValue( questions[ questionIndex ].answerNb);
  //     // sliderThumb.style.left =`${left}`;


  
  //   // let percentage = ((answer/range)*100) ;
  //   // let left=`${percentage}%`

  //   // console.log(`showAnswer = ${showAnswer}`);
  //   // console.log(`sliderThumb = ${sliderThumb}`);
    
  
  // }, [showAnswer, questionIndex]);
}, [showAnswer, questionIndex])

  // const [ value2, setValue2 ] = useState( 1 );
  const [ answer, setAnswer ] = useState( false );

  // 
  const divRef = useRef( null );
  const questionRef = useRef( null );

  const setThumbValues = () => {
    // if ((questions[ questionIndex + 1]) && showAnswer)
    // {
    // let sliderThumb = document.getElementsByClassName("MuiSlider-thumb");
    if(questions[ questionIndex + 1]) {
    let rangeValue=(questions[ questionIndex + 1].sliderMax) - (questions[ questionIndex + 1].sliderMin);
    let min = (questions[ questionIndex + 1].sliderMin);
    let max = (questions[ questionIndex + 1].sliderMax);
    let answerValue=( questions[ questionIndex + 1].answerNb)-(questions[ questionIndex + 1].sliderMin);
    let percentageValue=(((answerValue/rangeValue)*100))
    setRange(rangeValue);
      setAnswerNb(answerValue);
      setPercentage(percentageValue);
      setLeft(percentage);
      console.log(`range = ${range}, answerNb = ${answerNb}, percentage = ${percentage}, left = ${left} min=${sliderMin}, max=${sliderMax}`)
      let sliderThumb1 = document.querySelectorAll(".MuiSlider-thumb");
      console.log(`sliderThumb1 = ${sliderThumb1}, left = ${left}`);
      
    }
    // if(sliderThumbCurrent) 
    // {
    //   console.log(sliderThumbCurrent);
      // let sliderCSS = sliderThumbCurrent.style.cssText;
      // console.log(sliderCSS);
      // setCssText(sliderCSS);
      // console.log(left, cssText);
    
      // range = 100, answerNb = 42, percentage = 42, min=1, max=100
      // range = 180, answerNb = 80, percentage = 44.44444444444444, min=0, max=180
      // range = 30, answerNb = 5, percentage = 16.666666666666664, min=0, max=30
  //   } else if 
  //     ((questions[ questionIndex ]) && showAnswer)
  //     {
  //       let rangeValue=(questions[ questionIndex ].sliderMax) - (questions[ questionIndex ].sliderMin); // 60
  //       let answerValue=( questions[ questionIndex ].answerNb)-(questions[ questionIndex ].sliderMin); // 1948
  //       let min = (questions[ questionIndex ].sliderMin);
  //       let max = (questions[ questionIndex ].sliderMax);
  //       let percentageValue=(((answerValue/rangeValue)*100))
    
  //     setRange(rangeValue);
  //     setAnswerNb(answerValue);
  //     setPercentage(percentageValue);
  //     setLeft(percentage);
  //     console.log(`range = ${range}, answerNb = ${answerNb}, percentage = ${percentage}, min=${min}, max=${max}`) // range = 60, answerNb = 48, percentage = 80, min=1900, max=1960
  //     }
}

  const handleValueChange = ( event ) =>
  {

    getSliderValue( 0 );
    setValue( event.target.value);
    getSliderValue( value );
    checkAnswer();
    setAnswer( true );
    setThumbValues();
    setSliderThumbCurrent(sliderThumb[questionIndex]);
      console.log(`sliderThumbCurrent = ${sliderThumbCurrent}`);
      if(sliderThumbCurrent && left) {
        let sliderThumbCSS = sliderThumbCurrent.style.cssText;
        console.log(`sliderThumbCSS = ${sliderThumbCSS}, left = ${left}`);
        setCssText(`left: ${left.toFixed(0)};`)
        console.log(`${cssText}%`)
      }
      
    // console.log( `value = ${ value }, value2 = ${ value2 }` );
  };

  const changeValue = ( event ) =>
  {
    getSliderValue( 0 ); }

    const sliderLabel = [ ...document.querySelectorAll( ".css-yafthl-MuiSlider-markLabel" ) ];

    let sliderLabel1 = sliderLabel[ 1 ];
  // Set a Single Question
  useEffect( () =>
  {
    
    // code in useEffect runs after the component has rendered
    if ( questions.length > questionIndex )
    {
      setQuestion( questions[ questionIndex ] );
      setQuestionNext( questions[ questionIndex + 1 ] );
      setValue( questions[ questionIndex ].sliderMin);
      setValueSet( true );
      // setThumbValues()
      // if sliderLabel1 exists (because it has to be rendered first), change its text to the following:
      if ( sliderLabel1 ) { sliderLabel1.innerHTML = "3 heures"; }

    }
  }, [ questions, questionIndex ] );

  // Start the quiz
  const startQuiz = () =>
  {
    setShowStart( false ); 
    setShowQuiz( true );
    // setThumbValues();
    
  };

  // Check predicted answer against real answer and display a different answer text according to whether predicted answer is "correct", "bitHigh", "bitLow", "veryHigh" or "veryLow"
  const checkAnswer = ( event ) =>
  {
    const question = questions[ questionIndex ];
    if ( question.correct.value === predictedAnswer )
    {
      // setScore( score + 1 );
      setAnswerAssessment( question.correct.assessment );
      setAnswerText( question.correct.text );

    } else if ( predictedAnswer >= question.veryLow.min && predictedAnswer <= question.veryLow.max )
    {
      setAnswerAssessment( question.veryLow.assessment );
      setAnswerText( question.veryLow.text );
    } else if ( predictedAnswer >= question.bitLow.min && predictedAnswer <= question.bitLow.max )
    {
      setAnswerAssessment( question.bitLow.assessment );
      setAnswerText( question.bitLow.text );
    } else if ( predictedAnswer >= question.bitHigh.min && predictedAnswer <= question.bitHigh.max )
    {
      setAnswerAssessment( question.bitHigh.assessment );
      setAnswerText( question.bitHigh.text );
    } else if ( predictedAnswer >= question.veryHigh.min && predictedAnswer <= question.veryHigh.max )
    {
      setAnswerAssessment( question.veryHigh.assessment );
      setAnswerText( question.veryHigh.text );
    }

  };

  const showAnswerText = ( props ) =>
  {
    setShowAnswer( true );
    setValueSet( false );
    // setThumbValues();

    if ( divRef.current )
    {
      const childDiv = divRef.current;
      console.log( childDiv );
      setTimeout( () =>
      {
        childDiv.scrollIntoView( {
          behavior: 'smooth'
        } );
      }, 50 );
      const questionDiv = questionRef.current;
      console.log( questionDiv );
      questionDiv.style.opacity=0.3;
      questionDiv.style.transition = "all 0.5s";

    }
  };

  // Next Question - resets values, moves question index on
  const nextQuestion = () =>
  {

    setPredictedAnswer( 0 ); // resets slider value
    setQuestionIndex( questionIndex + 1 ); // increments question index by 1
    setAnswerText( '' ); // resets the answer text
    setShowAnswer( false ); // resets so answer is hidden
    setAnswer( false ); //
    setPredictedAnswerCheck( false ); // don't check the answer yet


    // console.log( `question.sliderMin = ${ question.sliderMin }` );
    setSliderDefault( questionNext.sliderMin ); // takes minimum from scale of next question
    setSliderMin( questionNext.sliderMin ); // takes minimum from scale of next question
    setSliderMax( questionNext.sliderMax ); // takes maximum from scale of next question

    const questionDiv = questionRef.current;
    console.log( questionDiv );
    questionDiv.style.opacity=1;

  };

  const getSliderValue = ( val, val2 ) =>
  {
    setPredictedAnswer( val ); // value of the slider === the user's answer
    setPredictedAnswerCheck( true ); // now check the answer
  };


  const getDefaultValue = ( value ) =>
  {
    return value
  };

  const resetPredictedAnswer = () =>
  {
    setPredictedAnswer( 0 );
  };

  // Show Result
  const gameOver = () =>
  {
    setPredictedAnswer( 0 ); // resets slider value
    setValue( 0 );
    getSliderValue( 0 );
    setSliderDefault( 1 );
    setShowResult( true );
    setShowStart( false );
    setShowQuiz( false );


  };
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (completed) {
      // Perform any cleanup actions here, if necessary
      // Then, reset the state of the component
      setCompleted(false);
    }
  }, [completed]);

  // Start the quiz again
  const startAgain = () =>
  {
    setShowStart( true );
    setShowResult( false );
    setAnswerText( '' );
    setAnswerAssessment( '' );
    setPredictedAnswer( 0 );
    setPredictedAnswerCheck( false );
    setShowAnswer( false );
    setQuestionIndex( 0 );
    setQuestion( {} );
    setQuestionNext( {} );
    setMarks( 0 );
    setSliderMin( 1 );
    setSliderMax( 100 );
    setSliderDefault( 1 );
    setValue( 0 );
    getSliderValue( 0 );
    setCompleted(true);
  };

 

  if (!completed) {
    // Render the quiz component here


  return (

<>



      <Start
        startQuiz={ startQuiz }
        showStart={ showStart }
      />
      <Quiz
        showQuiz={ showQuiz }
        question={ question }
        questions={ questions }
        checkAnswer={ checkAnswer }
        predictedAnswer={ predictedAnswer }
        answerAssessment={ answerAssessment }
        answerText={ answerText }
        showAnswerText={ showAnswerText }
        showAnswer={ showAnswer }
        questionIndex={ questionIndex }
        nextQuestion={ nextQuestion }
        getSliderValue={ getSliderValue }
        showResult={ showResult }
        gameOver={ gameOver }
        sliderMin={ sliderMin }
        sliderMax={ sliderMax }
        sliderDefault={ sliderDefault }
        resetPredictedAnswer={ resetPredictedAnswer }
        handleValueChange={ handleValueChange }
        value={ value }
        getDefaultValue={ getDefaultValue }
        predictedAnswerCheck={ predictedAnswerCheck }
        valueSet={ valueSet }
        sliderLabel1={ sliderLabel1 }
        sliderLabel={ sliderLabel }
        answer={ answer }
        divRef={ divRef }
        questionRef={ questionRef }
      />

      {/* Result Page */ }
      <Result
        showResult={ showResult }
        questions={ questions }
        marks={ marks }
        startAgain={ startAgain } />

      {/* </div> */ }
      </>


  )
} else {
  return null;
}
}



// const Home = () => {
//     return (
//         <>
        
//         </>
//     )
    
//   };
  
//   export default Home;