import React, { useState, useEffect } from 'react';

function TestChart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('./myData.json', { mode: 'no-cors' })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div>
      <h1>My Component</h1>
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <h2>{item.geo}</h2>
            <p>{item.value}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TestChart;
// Note that the fetch API is asynchronous, so it returns a Promise. In the useEffect hook, you're using the then() method to handle the Promise and set the state with the fetched data. The empty dependency array [] ensures that the effect only runs once, when the component mounts.
