import BarChartQ1 from '../components/charts/BarChartQ1';
import TestChart from '../components/charts/TestChart';

export default function Test ()
{
    return (
        <>
            <h1>Test</h1>
            <div><TestChart /></div>
        </>
    );
}