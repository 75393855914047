import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  export default function BarChart({ chartData }) {
    return (
      <div className="chart-container">
        <h2 style={{ textAlign: "center" }}>Men and women in national parliaments</h2>
        <Bar
          data={chartData}
          options={{
            plugins: {
                title: {
                  display: true,
                  text: '',
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                  max: 100
                },
              },
          }}
        />
      </div>
    );
  }

